#root {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

#root .content {
  flex-grow: 1;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}

.booking-frame {
  flex-grow: 1;
}
